.card-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Cria 5 colunas com largura igual */
    gap: 16px; /* Espaçamento entre os cards */
  }
  
  .column {
    display: flex;
    flex-direction: column;
    gap: 16px; /* Espaçamento entre os cards na coluna */
  }
  
  /* Estilo do card (pode ser o mesmo estilo usado no Card.js) */
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 180px;
  }
  
  .card img {
    width: 100%;
    max-height: 150px;
    object-fit: cover;
    border-radius: 2px;
    margin-bottom: 8px;
  }
  
  .card-content {
    text-align: center;
  }
  
  .card h2 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  
  .card p {
    font-size: 1rem;
  }
  