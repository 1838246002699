.outer-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 19vh;
    background: #16A085;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F4D03F, #16A085);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F4D03F, #16A085); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  }
  
  .centered-div {
    display: flex;
    justify-content: center;
    align-items: center;    
  }

  .centered-div img {
    width: 40%;
    height: auto;    
    padding: 10px;
  }